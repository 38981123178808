<template>
  <div class="warp"
       style="font-size:12px;"
       v-loading="Loading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="tab tab-top">
      <div class="tab-item toptab active">商品</div>
      <div class="tab-item toptab">订单</div>
    </div>
    <div class="main">
      <div class="page page-1 active">
        <el-row :gutter="12">
          <el-col :span="24">
            <div class="liebox uidbox">
              <div class="alpha"></div>
              <span class="title"
                    style="width:180px;margin-left:-90px;font-size:14px;"><i class="fa fa-user"></i>商品信息</span>
              <div style="overflow:overlay;height:90px;padding:0px 10px;">
                <p style="margin-bottom:0px;word-wrap:break-word;word-break:break-all;">商品说明: <span style="color:#c6abab;">{{goods_info.instructions}}</span></p>
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div style="padding:10px 10px;color:#ff8282;margin-top:16px;">
              <div style="text-align: center;margin-bottom:10px;">
                <h4>购买信息</h4>
              </div>
              <div style="padding:2px 0px;font-size: 10px;">
                <el-row :gutter="12">
                  <el-col :span="12">
                    <p style="margin-bottom:6px;">
                      <span>商品名称：</span>
                      <select v-model="form.goodid"
                              @change="GoodsChange"
                              style="width:140px;background:#101010;color:#808080;padding:0px 2px;border:1px solid #444;height:22px;">
                        <option :value="0">请选择商品</option>
                        <option v-for="(v, i) in info.goods_data"
                                :key="i"
                                :value="v.id">{{v.name}}</option>
                      </select>
                    </p>
                    <p style="margin-bottom:6px;"><span>商品单价：</span>
                      <span>{{goods_info.price}}（元）</span>
                      <el-tooltip class="item"
                                  effect="dark"
                                  content="Right Center 提示文字"
                                  placement="right"
                                  v-if="goods_info.preferential > 0">
                        <div slot="content">
                          <div style="text-align:center;">
                            <span style="color:#e29a9a;">批发优惠表</span>
                          </div>
                          <div style="margin-top:10px;color:#f80000;"
                               v-if="goods_info.preferential == 1">
                            <p v-for="(v, i) in goods_info.add_preferential"
                               :key="i">单批满{{v.num}}元，打{{v.fold}}折</p>
                          </div>
                          <div style="margin-top:10px;color:#f80000;"
                               v-if="goods_info.preferential == 2">
                            <p v-for="(v, i) in goods_info.add_preferential"
                               :key="i">单批购买{{v.num}}张卡，打{{v.fold}}折</p>
                          </div>
                        </div>
                        <span id="isdiscount"
                              style="color:#808080;">批发</span>
                      </el-tooltip>
                    </p>
                    <p style="margin-bottom:6px;"><span>购买数量：</span><input @change="GoodsNum"
                             type="number"
                             min="1"
                             max="99999999999"
                             v-model="form.quantity"
                             class="service__input"
                             style="width:126px;"> <span style="float:right;margin-right:26px;"
                            id="camiloNum"></span></p>
                    <p style="margin-bottom:6px;"
                       v-if="goods_info.preferential_jzi == 1">
                      <span>用优惠卷：</span>
                      <select v-model="form.preferential_jzi"
                              style="width:140px;background:#101010;color:#808080;padding:0px 2px;border:1px solid #444;height:22px;">
                        <option :value="0">不使用</option>
                        <option :value="1">使用</option>
                      </select>
                    </p>
                  </el-col>
                  <el-col :span="12">
                    <p style="margin-bottom:6px;"><span id="contactName">联系方式：</span>
                      <input v-if="goods_info.contact == 1"
                             type="text"
                             v-model="form.phone"
                             style="width:156px;"
                             class="service__input"
                             placeholder="QQ/手机号,订单查询的重要凭证!">
                      <input v-if="goods_info.contact == 2"
                             type="text"
                             v-model="form.phone"
                             style="width:156px;"
                             class="service__input"
                             placeholder="填写QQ,订单查询的重要凭证!">
                      <input v-if="goods_info.contact == 3"
                             type="text"
                             v-model="form.phone"
                             style="width:156px;"
                             class="service__input"
                             placeholder="填写手机号,订单查询的重要凭证!">
                      <input v-if="goods_info.contact == 4"
                             type="text"
                             v-model="form.phone"
                             style="width:156px;"
                             class="service__input"
                             placeholder="填写邮箱,订单查询的重要凭证!">
                    </p>
                    <p v-if="goods_info.check == 1"
                       style="margin-bottom:6px;"><span>游戏账号：</span><input type="text"
                             v-model="form.username"
                             style="width:156px;"
                             class="service__input"></p>
                    <p v-if="goods_info.check == 1"
                       style="margin-bottom:6px;"><span>游戏密码：</span><input type="password"
                             v-model="form.password"
                             style="width:156px;"
                             class="service__input"></p>
                    <p v-if="goods_info.gamePasswd == 1"
                       style="margin-bottom:6px;"><span>提卡密码：</span><input type="text"
                             v-model="form.tk_passwd"
                             style="width:156px;"
                             class="service__input"></p>
                    <p v-if="form.preferential_jzi == 1"
                       style="margin-bottom:6px;"><span>优惠卷码：</span><input type="text"
                             @change="GoodsNum"
                             v-model="form.preferential_key"
                             style="width:156px;"
                             class="service__input"></p>
                  </el-col>
                  <el-col :span="24">
                    <div style="margin-top:10px;">
                      <div style="padding:2px 0px;">
                        <p><span>充值方式：</span>
                          <el-radio-group v-model="form.pay"
                                          size="mini">
                            <el-radio v-for="(v, i) in info.pay_data"
                                      :key="i"
                                      :label="v.id"
                                      border>{{v.name}}</el-radio>
                          </el-radio-group>
                        </p>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <span style="position:absolute;right:20px;top:384px;">总应付：<span>{{total_money}} 元</span></span>
                <div class="col-sm-12"
                     style="text-align:center;">
                  <button class="btn-default"
                          lay-submit
                          lay-filter="submit"
                          style="position:absolute;left:18px;top:410px;"
                          @click="payOrder">去支付</button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 订单 -->
      <div class="page page-2">
        <div class="row"
             style="margin-right:0px;margin-left:0px;">
          <div class="col-sm-12"
               style="margin-top:43px;color:#adb80c;font-size: 12px;padding:0px 22px;">
            <p style="margin-bottom:0px">温馨提示：</p>
            <p style="margin-bottom:0px">1、订单支付后可能有延迟10-20秒到账和请留意查看（否则请联系服主处理）</p>
          </div>
          <div class="col-sm-12"
               style="margin-top:4px;text-align:center;">
            <p style="padding:22px 0px;">
              <input v-model="order_form.keywords"
                     placeholder="请输入联系方式/订单号查询..."
                     class="service__input"
                     style="height: 36px;width: 410px;border:1px solid #678267;background:#c5b48e;"><span @click="search"
                    style="cursor:pointer;padding:11px 14px;background:#0d0a8c;border-radius:2px;color: aliceblue;"> 搜索</span>
            </p>
          </div>
          <div class="col-sm-12"
               style="margin-top:14px;padding-right:12px;padding-left:12px;">
            <div style="height:262px;overflow:auto;background: rgb(28 27 27);">
              <div v-if="order_form.data.length <= 0"
                   style="text-align:center;padding:120px 0px;color:#616161;">
                暂无订单
              </div>
              <el-row v-else
                      :gutter="12">
                <el-col :span="24"
                        v-for="(v, i) in order_form.data"
                        :key="i"
                        class="order_lis">
                  <el-row :gutter="12">
                    <el-col :span="14">
                      <div style="display:inline-block;">
                        <p style="margin-bottom:4px">订单号码：{{v.order}}</p>
                        <p style="margin-bottom:4px">商品名称：{{v.goods_name}}</p>
                        <p style="margin-bottom:4px">付款状态：<span v-if="v.state == 0"
                                style="color:#f00;">待付款</span><span v-if="v.state == 1">已支付</span></p>
                        <p style="margin-bottom:0px">订单时间：{{v.time}}</p>
                      </div>
                    </el-col>
                    <el-col :span="10">
                      <div style="display:inline-block;">
                        <p style="margin-bottom:4px">下单数量：({{v.num}}张)</p>
                        <p style="margin-bottom:4px">订单金额：{{v.money}} 元</p>
                        <p style="margin-bottom:4px">出卡状态：<span v-if="v.ck_state == 0"
                                style="color:#f00;">未出卡</span><span v-if="v.ck_state == 1">已出卡<span style="color:#777272;"
                                  @click="GetCamilo(v.order,'')">（查询）</span></span></p>
                        <p style="margin-bottom:0px">支付方式：{{v.pay_name}}</p>

                      </div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="alert">
      <div class="alert_main"
           style="margin-top:170px;width:256px;"
           :style="TkDisplay">
        <div class="alert_title"><span>提卡密码</span></div>
        <div class="alert_con">
          <div class="alert_com">
            <div style="margin-bottom:6px;"><input v-model="tk_passwd"
                     type="password"
                     class="service__input"></div>
            <div style="text-align:center;padding:10px 0px">
              <div class="btn"
                   @click="GetCamilo(tk_order,tk_passwd)"
                   style="margin:0px 0px;display:inline-block;">确定</div>
              <div class="btn"
                   @click="TkDisplay = 'display:none'"
                   style="margin:0px 0px;display:inline-block;">取消</div>
            </div>
          </div>
        </div>
        <div class="alert_bottom"><span></span></div>
      </div>

      <div class="alert_bg"
           :style="KmDisplay">
        <div class="alert_main"
             style="margin-top:32px;width:490px;">
          <div class="alert_title"><span>卡密</span></div>
          <div class="alert_con">
            <div class="alert_com"
                 style="height:362px;">
              <textarea v-model="cdk_km"
                        style="width:96%;height:290px;background:#000;color:#848484;border:1px solid #5a0303;"></textarea>
              <div class="btn"
                   @click="KmDisplay = 'display:none'">关闭</div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>

      <div class="alert_bg"
           :style="MessageDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{ MessageMsg }}</span>
              <div class="btn"
                   @click="CloseAlert">关闭</div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
      <div class="alert_bg"
           :style="SuccessDisplay">
        <div class="alert_main">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com">
              <span>{{ MessageMsg }}</span>
              <div class="btn"
                   @click="SuccessAlert">关闭</div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>

      <div class="alert_bg"
           :style="PayDisplay">
        <div class="alert_main"
             style="margin-top:5%;width: 528px;">
          <div class="alert_title"><span>提示</span></div>
          <div class="alert_con">
            <div class="alert_com"
                 style="background: #fff;">
              <el-row>
                <el-col :span="11">
                  <div style="text-align:left;margin-left:10px;color:#b80c0c;">
                    <p style="margin-bottom:6px;">订单：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.order}}</span></p>
                    <p style="margin-bottom:6px;">金额：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.amount}}</span></p>
                    <p style="margin-bottom:6px;">付款：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.pay_name}}</span></p>
                    <p style="margin-bottom:6px;">数量：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.num}}</span></p>
                    <p style="margin-bottom:6px;">日期：<span style="display:inline-block;padding:0px; 0px;">{{pay_info.time}}</span></p>
                    <p style="margin-top:10px;margin-bottom:0px;color:#b80c0c;">因为窗口大小有限，部分支付接口无法完整显示付款码，请手动通过左右，上下滑动展示完整的付款码即可</p>
                  </div>
                </el-col>
                <el-col :span="13">
                  <div style="text-align: center">
                    <iframe v-if="pay_info.pay_type == 0"
                            frameborder="0"
                            scrolling="auto"
                            :src="pay_info.url"
                            style="width: 286px; height: 334px"></iframe>
                            <el-image v-else-if="pay_info.pay_type == 1"
                            style="width: 200px; height: 200px"
                            :src="pay_info.url"></el-image>
                    <!-- <vue-qr v-else-if="pay_info.pay_type == 1"
                            :size="200"
                            :text="pay_info.url" /> -->
                  </div>
                </el-col>
              </el-row>

              <p style="margin-top:10px;margin-bottom:0px;color:#b80c0c;">扫码完成，请不要点关闭，如20秒内不弹出结果，再关闭请手动查询订单</p>
              <div style="text-align:center;padding:10px 0px">
                <div class="btn"
                     @click="PayDisplayAlert"
                     style="margin:0px 0px;display:inline-block;">取消</div>
              </div>
            </div>
          </div>
          <div class="alert_bottom"><span></span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import Vue from "vue";
export default {
  name: "Goods",
  data () {
    return {
      MessageDisplay: "display:none", //弹出框显示
      SuccessDisplay: "display:none", //成功弹出框
      PayDisplay: "display:none", //支付
      MessageMsg: "", //弹出框消息
      KmDisplay: 'display:none',
      TkDisplay: 'display:none',
      Loading: false,
      info: {
        goods_data: [],//内容
        pay_data: [], //支付接口
      },
      index: null,
      form: {
        goodid: 0, //商品ID
        quantity: 1, //购买数量
        preferential_jzi: 0, //优惠卷使用0不使用 1使用
        phone: '', //联系方式
        username: '', //游戏账户
        password: '',//游戏密码
        preferential_key: '', //优惠卷key
        pay: '',
        tk_passwd: '',//提卡密码
      },
      goods_info: {
        id: 0, //id
        name: '', //商品名称
        price: '0.00', //单价
        contact: 1, //联系方式
        check: 0, //游戏密码检验
        preferential_jzi: 0, //优惠券 0关闭 1开启
        gamePasswd: 0, //是否开启游戏密码提卡 0关闭 1开启
        instructions: '', //说明
        preferential: 0, //折扣类型
        add_preferential: [], //折扣内容
      },
      order_form: {
        keywords: '',
        data: [],
      },
      pay_info: {
        url: '',
        name: '',
        order: '',
        amount: '0',
        pay_name: '',
        num: 0,
        time: '',
        pay_type: 0,
      },
      role_data: [],
      timing: null,
      total_money: 0,
      cdk_km: '',
      tk_passwd: '',
      tk_order: '',
    };
  },
  created () {
    this.getInfo();
  },
  methods: {
    //获取基本配置
    getInfo () {
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/goods/getInfo",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.info = response.data.info;
            if (this.info.goods_data.length >= 1) {
              this.index = 0
            }
            if (response.data.info.pay_data.length > 0) {
              this.form.pay = response.data.info.pay_data[0].id;
            }
          } else {
            this.PopMessage(true, response.data.msg);
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //下单
    payOrder () {
      if (this.Loading == true) {
        return false;
      }
      clearInterval(this.timing);
      this.Loading = true;
      this.form.key = this.$route.query.key;
      this.form.keyid = this.$route.query.id;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/goods/payOrder",
        // 传递参数
        data: this.form,
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.pay_info = response.data.info;
            this.PayDisplay = "display:block";
            this.timing = setInterval(() => {
              this.GetTimingOrder(response.data.info.order)
            }, 3000)
          } else {
            this.PopMessage(false, response.data.msg)
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });

    },
    //定时监测订单
    GetTimingOrder (order) {
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/goods/getTimingOrder",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          order: order,
        },
        responseType: "json",
      })
        .then((response) => {
          if (response.data.code == 200) {
            this.PayDisplayAlert()
            this.PopMessage(false, "支付成功，请手动查询订单提取卡密")
          }
        })
        .catch((error) => {
          // 请求失败，
          console.log(error);
        });
    },
    search () {
      if (this.Loading == true) {
        return false;
      }
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/goods/getOrder",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          keywords: this.order_form.keywords,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.order_form.data = response.data.info;
          } else {
            this.PopMessage(false, response.data.msg)
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //提卡
    GetCamilo (order, tk_passwd) {
      if (this.Loading == true) {
        return false;
      }
      this.TkDisplay = 'display:none';
      this.tk_order = '';
      this.tk_passwd = '';
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/goods/getCamilo",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          order: order,
          tk_passwd: tk_passwd,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.KmDisplay = 'display:block';
            this.cdk_km = response.data.cdk;
          } else if (response.data.code == 501) {
            this.tk_order = order;
            this.TkDisplay = 'display:block';
          } else {
            this.PopMessage(false, response.data.msg)
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    GoodsChange () {
      var goodsid = this.form.goodid;
      var data = {
        id: 0, //id
        name: '', //商品名称
        price: '0.00', //单价
        contact: 1, //联系方式
        check: 0, //游戏密码检验
        preferential_jzi: 0, //优惠券 0关闭 1开启
        gamePasswd: 0, //是否开启游戏密码提卡 0关闭 1开启
        instructions: '', //说明
        preferential: 0, //折扣类型
        add_preferential: [], //折扣内容
      }
      if (goodsid > 0) {
        this.info.goods_data.forEach((item) => {
          if (item.id == this.form.goodid) {
            data = item;
          }
        })
      }
      this.goods_info = data
      this.GoodsNum()
    },
    GoodsNum () {
      this.Loading = true;
      this.axios({
        // 默认请求方式为get
        method: "post",
        url: Vue.prototype.config.webplugurl + "/goods/getGoodsNum",
        // 传递参数
        data: {
          key: this.$route.query.key,
          keyid:this.$route.query.id,
          goodid: this.form.goodid,
          num: this.form.quantity,
          preferential_jzi: this.form.preferential_jzi,
          preferential_key: this.form.preferential_key,
        },
        responseType: "json",
      })
        .then((response) => {
          this.Loading = false;
          if (response.data.code == 200) {
            this.total_money = response.data.money;
          } else {
            this.PopMessage(false, response.data.msg)
          }
        })
        .catch((error) => {
          this.Loading = false;
          // 请求失败，
          console.log(error);
        });
    },
    //弹出框状态
    PopMessage (type, msg) {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
      this.MessageMsg = msg;
      if (type == false) {
        this.MessageDisplay = "display:block";
      } else {
        this.SuccessDisplay = "display:block";
      }
    },
    //关闭窗口
    CloseAlert () {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
    },
    SuccessAlert () {
      this.SuccessDisplay = "display:none";
      this.MessageDisplay = "display:none";
      this.getInfo();
    },
    //关闭支付页面
    PayDisplayAlert () {
      clearInterval(this.timing);
      this.PayDisplay = "display:none";

    },
  },
};
$(function () {
  $('.toptab').click(function () {
    var index = $(this).index();
    $('.page').removeClass('active');
    $('.toptab').removeClass('active');
    $(this).addClass('active');
    $('.page').eq(index).addClass('active');
  });
  $('.midtab').click(function () {
    var index = $(this).index();
    $('.quest-page-item').removeClass('active');
    $('.midtab').removeClass('active');
    $(this).addClass('active');
    $('.quest-page-item').eq(index).addClass('active');
  });
});
    </script>

<style src="../../../public/static/plug/goods/game/static/css/game.css" scoped>
</style>
<style src="../../../public/static/plug/goods/game/static/css/body.css" scoped>
</style>
<style lang="scss" scoped>
.main {
  /deep/ .el-row {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
}
</style>
<style>
#root,
body,
html {
  min-width: 0px;
}
</style>